<template>
  <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>AIOT</el-breadcrumb-item>
           <el-breadcrumb-item>家居设备</el-breadcrumb-item>
           <el-breadcrumb-item>设备报警</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <!-- 表格 -->
     <div class="content-flex-box">
        <el-col :span="6" class="left-table-bg">
           <el-row class="left-table">
              <el-row>
                 <el-input class="width-percent-20 m-bottom-5 m-right-10" v-model="userName" placeholder="会员号"  @clear="searchMembers" clearable>
                    <i slot="suffix" class="suffix-cursor el-input__icon el-icon-search" @click="searchMembers"></i>
                 </el-input>
              </el-row>
              <el-table
                    ref="hotelTable"
                    v-loading="sideLoad"
                    :data="sideData"
                    border
                    @row-click="handleCurrentChange"
                    highlight-current-row
                    style="width: 100%;"
                    max-height="780"
                    :stripe="true"
              >
                 <el-table-column :label="$t('msg.head_portrait')" width="70">
                    <template slot-scope="scope">
                       <img v-if="scope.row.headPortrait" :src="scope.row.headPortrait" width="40" height="40" class="v-align-middle"/>
                       <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" width="40" height="40" class="v-align-middle" alt="">
                    </template>
                 </el-table-column>
                 <el-table-column prop="userName" :label="$t('msg.member_ID')"></el-table-column>
              </el-table>
           </el-row>
        </el-col>
        <el-col :span="18" class="right-table-bg">
           <el-row class="right-table">
              <el-row class="search-box">
                 <el-row class="search-row">
                    <el-row class="search-item">
                       <label v-text="$t('msg.dev_type_a')">设备类型：</label>
                       <el-select  class="width-220" v-model="deviceTypeId" :placeholder="$t('msg.select')" clearable>
                          <el-option v-for="item in deviceTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
                       </el-select>
                    </el-row>
                    <el-row class="search-item">
                       <label v-text="$t('msg.alarm_time_a')">报警时间：</label>
                       <date-packer :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
                    </el-row>
                    <el-button type="primary" @click="handleQuery()" ><span v-text="$t('msg.search')">搜索</span></el-button>
                    <el-button  type="primary" @click="handleReset()" plain><span v-text="$t('msg.reset')">重置</span></el-button>
                 </el-row>
              </el-row>
              <el-row class="table-box">
                 <el-table
                       v-loading="loading"
                       :data="tableData"
                       highlight-current-row
                       style="width: 100%"
                       :stripe="true"
                 >
                    <el-table-column :label="$t('msg.number')" width="70">
                       <template slot-scope="scope">
                          {{scope.$index + 1}}
                       </template>
                    </el-table-column>
                    <el-table-column prop="userName" :label="$t('msg.member_ID')" min-width="110"></el-table-column>
                    <el-table-column prop="deviceName" :label="$t('msg.dev_name')" min-width="160"></el-table-column>
                    <el-table-column prop="versionsName" :label="$t('msg.dev_type')" min-width="160"></el-table-column>
                    <el-table-column prop="content" :label="$t('msg.msg_content')" :min-width="msgWidth"></el-table-column>
                    <el-table-column prop="createTime" :label="$t('msg.alarm_time')" min-width="150" sortable></el-table-column>
                 </el-table>
              </el-row>
              <!-- 分页 -->
              <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="currChange"/>
           </el-row>
        </el-col>
     </div>
  </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   export default {
      data() {
         return {
            tableData: [],     // 表格数据
            sideData: [],      // 侧边栏数据
            deviceTypes: [],   // 设备类型
            sideLoad: false,    // 侧边栏加载效果
            loading: true,     // 表格加载效果
            total: 0,          // 数据总数
            userName: '',      // 会员名称
            dateValue: [],     // 报警时间段
            boxIcon: true,     // 酒店显示
            msgWidth: 0,       // 内容栏宽度
            /* 查询列表字段 */
            limit: 0,          // 每页数
            page: 1,           // 当前页
            userId: '',        // 会员ID
            deviceTypeId: '',  // 设备类型ID
            stTime: '',        // 开始日期
            edTime: '',        // 结束日期
         }
      },
      created() {
         if (localStorage.lang === 'zh_CN') {
            this.msgWidth = 240
         } else {
            this.msgWidth = 320
         }
      },
      mounted () {
         this.limit = sessionStorage.getItem('pageSize') * 1
         // this.getMembers()
         this.getCategory()
         this.getAlarmList()
      },
      methods: {
         // 获取会员列表
         getMembers() {
            const url = urlObj.members
            let param = {
               userName: this.userName
            }
            this.$axios.post(url, param, 'json').then((res) => {
               if (res.success) {
                  let data = res.records
                  if (!data || data.length <= 0) {
                     this.sideData = []
                     return
                  }
                  this.sideLoad = false
                  this.sideData = data.map(item => {
                     if (item.headPortrait.substr(0,4) !== 'http'){
                        item.headPortrait = item.headPortrait && window.getResourcesHost() + item.headPortrait
                     }
                     return {id: item.id, headPortrait: item.headPortrait, userName: item.userName}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 搜索会员
         searchMembers(){
            this.getMembers()
         },
         // 获取警报列表
         getAlarmList() {
            const url = urlObj.getHomeAlarmList + `?limit=${this.limit}&page=${this.page}`
            const param = {
               userId: this.userId,
               deviceTypeId: this.deviceTypeId,
               stTime: this.stTime,
               edTime: this.edTime
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  let tableData = res.records
                  tableData.forEach(item => {
                     item.content = this.filterAlarmInfo(item.roomNo, item.versionsName, item.logType)
                  })
                  this.tableData = tableData
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 校验报警消息
         filterAlarmInfo (roomNo, alarmType, battery) {
            let electricityAlarm = roomNo + '房间设备电量不足'
            // 语言判断
            if (localStorage.getItem('lang') === 'en_US') {
               electricityAlarm = 'Device in room ' + roomNo + ' ，low battery '
            }
            switch (battery) {
               case 'POWER_ALARM':
                  battery = electricityAlarm
                  break
               default:
                  battery = '—'
                  break
            }
            return battery
         },
         // 侧边栏点击
         handleCurrentChange(val) {
            this.userId = val.id
            this.getAlarmList()
         },
         // 获取设备类型
         getCategory(){
            const url = urlObj.getHomeCategoryList
            this.$axios.post(url, {}).then(res => {
               if (res.success) {
                  let deviceTypes = res.records
                  this.deviceTypes = deviceTypes.map(item => {
                     return {id: item.id, name: item.name}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取日期段
         getDatePacker(val){
            this.dateValue = val
         },
         // 查询警报
         handleQuery(){
            if (this.dateValue) {
               this.stTime = this.dateValue[0]
               this.edTime = this.dateValue[1]
            }
            this.getAlarmList()
         },
         // 重置查询
         handleReset() {
            this.dateValue = ''
            this.deviceTypeId = ''
         },
         // 改变每页数
         pageChange(num){
            this.limit = num
            this.getAlarmList()
         },
         // 改变当前页
         currChange(num){
            this.page = num
            this.getAlarmList()
         },
      }
   }
</script>
